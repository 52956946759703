<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生选择
-->
<!--view 模板-->
<template>
  <div>
    <el-dialog
      title="选择学生"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="30%"
      center
      append-to-body
      :show-close="false"
    >
      <el-scrollbar style="height: 400px" class="myscrollbar">
        <sy-xsselect
          v-if="visible"
          ref="xsselect"
          :checkNodes="checkNodes"
          :checkKeys="checkKeys"
        ></sy-xsselect>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import xsselect from '../../components/common/xsselect.vue'
export default {
  props: {
    visible: {
      // 显示隐藏
      type: Boolean,
      default: false,
    },
    checkNodes: {
      type: Array,
    },
    checkKeys: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  components: {
    'sy-xsselect': xsselect,
  },
  methods: {
    ok() {
      this.$emit(
        'ok',
        this.$refs.xsselect.mycheckNodes,
        this.$refs.xsselect.mycheckKeys
      )
      // this.$emit("close");
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<!-- CSS样式 -->
<style></style>
