<template>
  <div class="content">
    <div class="left">
      <el-tree
        @node-click="changebm"
        :data="tree"
        :props="moduleProps"
        :expand-on-click-node="false"
        node-key="id"
        ref="tree"
        :check-strictly="true"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>
            <i :class="data.icon" style="margin-right: 5px"></i>{{ data.text }}
          </span>
        </span>
      </el-tree>
    </div>
    <div class="right">
      <div class="inputgroup">
        <el-input
          placeholder="请输入姓名"
          v-model="xm"
          class="input-with-select"
          size="small"
        >
          <el-button
            type="primary"
            slot="append"
            icon="el-icon-search"
            @click="GetRY"
          ></el-button>
        </el-input>
      </div>
      <el-tree
        :data="rytree"
        :props="moduleProps"
        :expand-on-click-node="false"
        node-key="id"
        ref="rytree"
        show-checkbox
        @check-change="handleClick"
        :check-strictly="true"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>
            <i :class="data.icon" style="margin-right: 5px"></i>{{ data.text }}
          </span>
        </span>
      </el-tree>
    </div>
  </div>
</template>
<script>
import { GetBJTree, GetXSTree } from '../../api/jcxx'
export default {
  props: {
    checkNodes: {
      type: Array,
    },
    checkKeys: {
      type: Array,
    },
  },
  data() {
    return {
      tree: [],
      rytree: [],
      moduleProps: {
        children: 'childNodes',
        label: 'text',
      },
      xm: '',
      bjbh: '',
      mycheckNodes: this.checkNodes,
      mycheckKeys: this.checkKeys,
    }
  },
  created() {
    GetBJTree().then((res) => {
      if (res.code === 200) {
        this.tree = res.data
        if (this.mycheckKeys) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(this.mycheckKeys) // 给树节点赋值
          })
        }
      }
    })
  },
  methods: {
    changebm(node) {
      this.bjbh = node.id
      this.xm = ''
      this.GetRY()
    },
    GetRY() {
      GetXSTree({ BJBH: this.bjbh, XM: this.xm }).then((res) => {
        if (res.code === 200) {
          this.rytree = res.data
          if (this.mycheckKeys) {
            this.$nextTick(() => {
              this.$refs.rytree.setCheckedKeys(this.mycheckKeys) // 给树节点赋值
            })
          }
        }
      })
    },
    // 点击节点
    handleClick() {
      this.mycheckNodes = this.$refs.rytree.getCheckedNodes()
      this.mycheckKeys = this.$refs.rytree.getCheckedKeys()
    },
  },
}
</script>
<style scoped>
.content {
  display: flex;
  height: 100%;
}
.left {
  width: 50%;
  border-right: 1px solid #ccc;
  height: 500px;
}
.right {
  width: 50%;
}
.left-item {
}
.inputgroup {
  display: flex;
  padding: 0 10px;
}
</style>
