<!--综合测评选项加分登记-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评选项</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col
            :span="6"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">类别:</span>
            <el-select
              v-model="lb"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                label="德育素质"
                :value="1"
              ></el-option>
              <el-option
                label="智育素质"
                :value="2"
              ></el-option>
              <el-option
                label="体育素质"
                :value="3"
              ></el-option>
              <el-option
                label="美育素质"
                :value="4"
              ></el-option>
              <el-option
                label="劳动素质"
                :value="5"
              ></el-option>
              <el-option
                label="能力素质"
                :value="6"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">评分内容:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入评分内容"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="lb"
          label="类别"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lb === 1">德育素质</el-tag>
            <el-tag v-if="scope.row.lb === 2">智育素质</el-tag>
            <el-tag v-if="scope.row.lb === 3">体育素质</el-tag>
            <el-tag v-if="scope.row.lb === 4">美育素质</el-tag>
            <el-tag v-if="scope.row.lb === 5">劳动素质</el-tag>
            <el-tag v-if="scope.row.lb === 6">能力素质</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="lx"
          label="类型"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lx === 1">加分要素</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.lx === 2"
            >减分要素</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="pfnr"
          label="评分内容及标准"
        > </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              @click="Applay(scope.row)"
              type="text"
              size="small"
            >
              登记
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="综合素质测评项目"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-dialog
        title="学生信息(双击选择)"
        :visible.sync="dialogStuVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5 ml20 fcolor42">学号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入学号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getStuDataPageList"
            style="margin-left: 10px"
          >搜索</el-button>
        </div>
        <el-table
          :data="selectStuData"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selStu"
        >
          <el-table-column
            property="xh"
            label="学号"
            width="150"
          ></el-table-column>
          <el-table-column
            property="xm"
            label="姓名"
            width="150"
          ></el-table-column>
          <el-table-column
            property="bjmc"
            label="班级"
          ></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleStuCurrentChange"
            :current-page="stupagination.page"
            :page-size="stupagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="stupagination.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <sy-bjdialog
        :visible="bjDialogVisible"
        :checkNodes="bjlist"
        :checkKeys="selectbjbh"
        @ok="bjok"
        @close="bjDialogVisible = false"
      ></sy-bjdialog>
      <sy-xsdialog
        :visible="xsDialogVisible"
        :checkNodes="xslist"
        :checkKeys="selectxs"
        @ok="xsok"
        @close="xsDialogVisible = false"
      ></sy-xsdialog>
      <el-scrollbar
        style="height: 550px"
        class="myscrollbar"
      >
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-form-item
            label="评分内容及标准"
            label-width="150px"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              readonly
              v-model="PFNR"
            >
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="选项"
                label-width="150px"
                prop="XID"
              >
                <el-select
                  v-model="forminfo.XID"
                  style="width:100%"
                  @change="xmchange"
                >
                  <el-option
                    v-for="(item, index) in childList"
                    :key="index"
                    :label="
                      item.sm
                    "
                    :value="
                      item.id
                    "
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="分数"
                label-width="150px"
                prop="FS"
              >
                <el-input
                  v-model="forminfo.FS"
                  readonly
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="学期"
                label-width="150px"
                prop="XQ"
              >
                <el-select
                  v-model="forminfo.XQ"
                  style="width:100%"
                >
                  <el-option
                    v-for="(item, index) in xqlist"
                    :key="index"
                    :label="
                      item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'
                    "
                    :value="
                      item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'
                    "
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="加分说明"
            label-width="150px"
            prop="JFSM"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5 }"
              placeholder="请输入内容"
              v-model="forminfo.JFSM"
            >
            </el-input>
          </el-form-item>
          <el-row
            :gutter="40"
            style="margin-left: 130px"
          >
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">选择加分班级</span>
                <el-button
                  type="text"
                  @click="bjDialogVisible = true"
                >设置</el-button>
              </div>
              <div class="tag-warp">
                <el-tag
                  type="warning"
                  closable
                  @close="delbj(index)"
                  v-for="(item, index) in bjlist"
                  :key="index"
                ><i class="fa fa-group">{{ item.bjmc }}</i></el-tag>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="40"
            style="margin-left: 130px"
          >
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">选择加分人员</span>
                <el-button
                  type="text"
                  @click="xsDialogVisible = true"
                >设置</el-button>
              </div>
              <div class="tag-warp">
                <el-tag
                  closable
                  @close="delxs(index)"
                  v-for="(item, index) in xslist"
                  :key="index"
                ><i class="fa fa-user">{{ item.xm }}</i></el-tag>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetZHCPXMPageList, saveFDYJF, getChildList } from '../../api/zhcp'
import { getAuthorizeButtonColumnList, getXQList } from '../../api/system'
import { getXSPageList } from '../../api/jcxx'
import moment from 'moment'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import bjdialog from '../../components/dialog/bjselect.vue'
import xsdialog from '../../components/dialog/xsselect.vue'
export default {
  components: {
    'sy-bjdialog': bjdialog, // 挂载组件
    'sy-xsdialog': xsdialog, // 挂载组件
  },
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      selkeyword: '',
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      stupagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      selectStuData: [],
      dialogStuVisible: false,
      windowHeight: document.documentElement.clientHeight - 50, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      lx: 1,
      lb: '',
      forminfo: {
        ID: '',
        XID: '',
        PID: '',
        XQ: '',
        JFSM: '',
        FS: 0,
        XHList: [],
        BJBHList: [],
      },
      xqlist: [],
      PFNR: '',
      spzt: null,
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        XH: [{ required: true, message: '请选择学生', trigger: 'blur' }],
        JFSM: [
          {
            required: true,
            message: '请填写内容',
            trigger: 'blur',
          },
        ],
        FS: [{ required: true, message: '请填写分数', trigger: 'blur' }],
        XQ: [{ required: true, message: '请选择学期', trigger: 'blur' }],
        XID: [{ required: true, message: '请选择加分项目', trigger: 'blur' }],
      },
      bjDialogVisible: false,
      bjlist: [],
      selectbjbh: [],
      xsDialogVisible: false,
      xslist: [],
      selectxs: [],
      childList: [],
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.selectbjbh = []
      this.bjlist = []
      this.selectxs = []
      this.xslist = []
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    handleStuCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.stupagination.page = val
      this.getStuDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetZHCPXMPageList({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: this.lx,
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    showAdd() {
      this.dialogFormVisible = true
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
      }
    },
    deleteinfo() {},
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          console.log(this.selectbjbh)
          this.forminfo.BJBHList = this.selectbjbh
          this.forminfo.XHList = this.selectxs
          saveFDYJF(this.forminfo)
          this.$message.success('保存成功')
          this.dialogFormVisible = false
          this.dialogDetailVisible = false
        }
      })
    },
    Applay(row) {
      this.forminfo.PID = row.id
      this.PFNR = row.pfnr
      getXQList().then((res) => {
        if (res.code === 200) {
          this.xqlist = res.data
        }
      })
      getChildList({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.childList = res.data
        }
      })
      this.dialogFormVisible = true
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    // 获取分页信息
    getStuDataPageList() {
      getXSPageList({
        queryJson: JSON.stringify({
          keyword: this.selkeyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.stupagination.page = res.data.page
            this.stupagination.total = res.data.total
            this.stupagination.records = res.data.records
            this.selectStuData = res.data.rows
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectstu() {
      if (this.operation === 1) {
        this.dialogStuVisible = true
        this.getStuDataPageList()
      }
    },
    selStu(row, column, event) {
      this.xsinfo = row
      this.forminfo.XH = row.xh
      this.forminfo.XM = row.xm
      this.dialogStuVisible = false
    },
    bjok(n, k) {
      const bj = n
      this.bjlist = []
      for (var i = 0; i < bj.length; i++) {
        this.bjlist.push({
          bjbh: bj[i].id,
          bjmc: bj[i].text,
        })
      }
      this.selectbjbh = k
      this.bjDialogVisible = false
    },
    delbj(index) {
      this.selectbjbh.splice(index, 1)
      this.bjlist.splice(index, 1)
      this.$forceUpdate() // 强制刷视图
    },
    xsok(n, k) {
      const list = n
      var tmp = []
      for (var i = 0; i < list.length; i++) {
        tmp.push({
          xh: list[i].id,
          xm: list[i].text,
        })
      }
      this.xslist = tmp
      this.selectxs = k
      this.xsDialogVisible = false
    },
    delxs(index) {
      this.xslist.splice(index, 1)
      this.selectxs.splice(index, 1)
      this.$forceUpdate() // 强制刷视图
    },
    xmchange(e) {
      this.forminfo.FS = this.childList.find((object) => object.id === e).fs
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
.ry-top-left {
  font-size: 14px;
  color: #606266;
}
.tag-warp {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  padding: 10px;
  min-height: 50px;
}
.el-tag {
  margin-right: 10px;
  margin-top: 5px;
}
</style>
